
import { mapMutations, mapActions } from 'vuex'
import LoginViaOtp from '~/components/LoginViaOtp.vue'

export default {
  layout: 'line',
  components: {
    LoginViaOtp
  },
  data () {
    return {
      error_message: '',
      otp: '',
      phone: '',
      otpOption: 'phone',
      verify: 1,
      email: '',
      code: '',
      isNotLineApp: false,
      lineProfile: null,
      lineToken: null,
      userProfile: null,
      liffID: this.$line().orders.liffId
    }
  },
  watch: {
    lineProfile: {
      handler (lineProfile) {
        console.log('lineProfile changed in line.vue:', lineProfile)
      },
      immediate: true
    }
  },
  created () {
    if (process.client) {
      const isStaging = process.env.NODE_ENV !== 'production'
      if (isStaging) {
        // Import VConsole only in the staging environment
        import('vconsole').then(({ default: VConsole }) => {
          const vConsole = new VConsole()
          console.log('vConsole', vConsole)
        })
      }
      this.initLiff()
    }
  },
  mounted () {
  },
  methods: {
    ...mapMutations([
      'showLoading',
      'hideLoading'
    ]),
    ...mapActions([
      'initializeApp',
      'isTokenExpired'
    ]),
    async initLiff () {
      console.log('--- initLiff ---')
      const thisObject = this
      const liffID = thisObject.$line().liffId
      if (process.client) {
      // window.onload = async function (e) {
        console.log('<-- window onload -->')
        if (window.liff.isInClient()) {
          await window.liff.init({
            liffId: liffID,
            withLoginOnExternalBrowser: true
          }).then().catch((e) => {
            console.log('catch liff', e.message, ' liff ID >>> ', liffID)
          })

          console.log('isLoggedIn :: ', window.liff.isLoggedIn())
          if (window.liff.isLoggedIn()) {
            thisObject.lineProfile = await window.liff.getDecodedIDToken()
            thisObject.lineToken = await window.liff.getIDToken()
            console.log('lineProfile', thisObject.lineProfile, 'lineToken', thisObject.lineToken)
          } else {
            window.liff.login()
          }
        } else {
          thisObject.$router.push(
            thisObject.localeLocation({ name: 'auth-login-via-phone' })
          )
        }
      }
    },
    async initializeLiff () {
      // const redirectUrl = this.localeLocation({ name: 'auth-line' })
      // const loginOptions = {
      //   redirectUri: 'https://7b1c-124-121-1-58.ngrok-free.app/th/auth/line'
      // }
      console.log('*** initializeLiff ***', this.liffID)
      await window.liff.init({ liffId: this.liffID })
      // const thisObject = this
      if (!window.liff.isInClient()) {
        if (window.liff.isLoggedIn()) {
          console.log('>>>><<<<')
          try {
            this.lineProfile = await window.liff.getDecodedIDToken()
            this.lineToken = await window.liff.getIDToken()
            console.log(this.lineProfile, this.lineToken)
            if (this.lineProfile &&
                  !this.isTokenExpired(this.lineProfile.exp)) {
              this.$axios.$get('line/user/token?token=' + this.lineToken)
                .then((res) => {
                  console.log('>>> res <<<', res)
                  if (res.status) {
                    console.log('ttttokennn', res.token)
                    // this.$store.dispatch('authenticateSmsUser', res)
                  }
                }).catch((e) => {
                  console.log('error line token', e.message)
                })
            } else {
              window.liff.logout()
            }
          } catch (error) {
            console.error('Error initializing LIFF:', error)
          }
        } else {
          window.liff.login()
        }
      } else {
        console.log('test-test-test')
        // this.$router.push(this.localeLocation({ name: 'auth-login-via-phone' }))
      }
    }
    // if (!window.liff.isInClient()) {
    //   try {
    //     await window.liff.init({ liffId: this.liffID })

    //     // Check if the user is logged in
    //     console.log('isLoggedIn', window.liff.isLoggedIn())
    //     if (window.liff.isLoggedIn()) {
    //       // Check if the access token is still valid by attempting to get the user's profile
    //       try {
    //         const profile = await window.liff.getProfile()
    //         this.lineProfile = await window.liff.getDecodedIDToken()
    //         this.lineToken = await window.liff.getIDToken()
    //         this.userProfile = profile
    //         console.log('lineProfileeee >>> ', this.lineProfile)
    //         if (
    //           this.lineProfile &&
    //           !this.isTokenExpired(this.lineProfile.exp)
    //         ) {
    //           this.$axios
    //             .$get('line/user/token?token=' + this.lineToken)
    //             .then((res) => {
    //               console.log('>>> res <<<', res)
    //               if (res.status) {
    //                 console.log('ttttokennn', res.token)
    //                 // this.$store.dispatch('authenticateSmsUser', res)
    //               }
    //             }).catch((e) => {
    //               console.log('error line token', e.message)
    //             })
    //         }
    //         console.log('User profile:', profile, this.lineProfile)
    //       } catch (error) {
    //         // The access token is likely invalid or expired
    //         console.error('Error getting user profile:', error)
    //       }
    //     } else {
    //       // User is not logged in
    //       console.log('User is not logged in')
    //       await window.liff.login()
    //     }
    //   } catch (error) {
    //     console.error('Error initializing LIFF:', error)
    //   }
    // } else {
    //   console.log('cannot open from line.')
    //   if (this.isAuthenticated === false) {
    //     this.$router.push(
    //       this.localeLocation({ name: 'auth-login-via-phone' })
    //     )
    //   }
    // }
    // async initializeApp () {
    //   console.log('*** initApp:line ***')

    //   try {
    //     await window.liff.init({ liffId: this.liffID })

    //     // Get user profile
    //     const lineProfile = await window.liff.getDecodedIDToken()
    //     console.log('* lineProfile *', lineProfile)
    //     // Check if the token is expired
    //     if (lineProfile !== null && this.isTokenExpired(lineProfile.exp)) {
    //       console.log('line token is expired')
    //       window.liff.logout()
    //     }

    //     // Check if the user is logged in
    //     if (!window.liff.isLoggedIn()) {
    //       console.log('not logged in')
    //       window.liff.login()
    //     }

    //     console.log('initialized successfully')
    //     // ... remaining code ...
    //   } catch (error) {
    //     console.log('Error initializing LIFF:', error.message)
    //   }
    // }
  }
}
